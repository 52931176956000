import { Box } from '@mui/material'
import { PhoenixBaseCard } from 'componix'
import { useState } from 'react'
import { useGetCarrierContacts } from '../../../cache/carrierCache'
import useSetAlertDetails from '../../../hooks/workQueue/useSetAlertDetails'
import CarrierContactAddressType from '../../../models/Carrier/CarrierContactAddressType'
import SearchDropdown from '../../SearchDropdown/SearchDropdown'
import CarrierContactsTable from './CarrierContactsTable'

interface CarrierContactsCardProps {
  guid: string | undefined
  contactTypes: CarrierContactAddressType[]
}

const CarrierContactsCard = ({ guid, contactTypes }: CarrierContactsCardProps) => {
  const [selectedContactType, setSelectedContactType] = useState<string>(contactTypes[0]?.addressTypeCode)
  const contactTypeOptions = contactTypes.map((ct) => ({
    id: ct.addressTypeCode,
    value: ct.addressTypeCode,
    label: ct.addressTypeDescription,
  }))

  const { data = [], isLoading, isError, lazyFetch } = useGetCarrierContacts(guid as string, selectedContactType)
  useSetAlertDetails([isError])

  const handleContactTypeSelect = (event: { target: { value: string } }) => {
    setSelectedContactType(event.target.value)
  }

  return (
    <PhoenixBaseCard cardTitle={'Contacts'} collapsible={true} onExpand={lazyFetch} isLoading={false}>
      <Box sx={{ margin: '12px auto 6px 12px', minWidth: '200px' }}>
        <SearchDropdown
          handleSelect={handleContactTypeSelect}
          labelText={'Contact Type'}
          value={selectedContactType}
          data-testid="CarrierDetailContactTypeDropdown"
          error={false}
          helperText={null}
          options={contactTypeOptions}
          size={'small'}
        />
      </Box>
      <CarrierContactsTable data={data} isLoading={isLoading} />
    </PhoenixBaseCard>
  )
}

export default CarrierContactsCard
