import { PhoenixBaseCard } from 'componix'
import { useGetCarrierLossCost } from '../../../cache/carrierCache'
import useSetAlertDetails from '../../../hooks/workQueue/useSetAlertDetails'
import CarrierLossCostMultiplierTable from './CarrierLossCostMultiplierTable'

interface CarrierLossCostMultiplierCardProps {
  guid: string | undefined
}

export const CarrierLossCostMultiplierCard = ({ guid }: CarrierLossCostMultiplierCardProps) => {
  const { data = [], isLoading, isError, lazyFetch } = useGetCarrierLossCost(guid as string)
  useSetAlertDetails([isError])

  return (
    <PhoenixBaseCard cardTitle={'Loss Cost Multiplier'} collapsible={true} onExpand={lazyFetch} isLoading={isLoading}>
      <CarrierLossCostMultiplierTable data={data} isLoading={isLoading} />
    </PhoenixBaseCard>
  )
}

export default CarrierLossCostMultiplierCard
