import { useTheme } from '@mui/material'
import {
  CommunicationTimeline,
  NoResultsFound,
  PhoenixBaseCard,
  PhoenixBaseTable,
  PhoenixBaseTableProps,
  PhoenixLink,
  StatusBadge,
} from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { useState } from 'react'
import { useGetComboOwnership } from '../../cache/comboCache'
import { useBoolean } from '../../hooks/useBoolean'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { ComboOwnershipResponse } from '../../models/WorkItemDetails/ComboOwnershipResponse'
import { tableCellTitleCase } from '../../utils/table/tableCellTitleCase'
import OwnershipDetailsModal from '../Modals/OwnershipDetailsModal'

const columns: MRT_ColumnDef<ComboOwnershipResponse>[] = [
  {
    accessorKey: 'entityName',
    header: 'Entity Name',
    // eslint-disable-next-line react/prop-types
    Cell: ({ cell }) => tableCellTitleCase(cell.getValue<string>()),
    minSize: 240,
  },
  {
    accessorKey: 'coverageId',
    header: 'Coverage ID',
    size: 40,
    // eslint-disable-next-line react/prop-types
    Cell: ({ cell }) => <PhoenixLink to={`/coverages/${cell.getValue<string>()}`} value={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'effectiveDate',
    header: 'Effective Date',
    size: 40,
  },
  {
    accessorKey: 'expirationDate',
    header: 'Expiration Date',
    size: 40,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    // eslint-disable-next-line react/prop-types
    Cell: ({ cell }) => <StatusBadge description={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'manual',
    header: 'Manual',
    maxSize: 80,
    size: 20,
  },
  {
    accessorKey: 'notes',
    header: 'Notes',
    maxSize: 80,
    size: 20,
  },
  {
    accessorKey: 'naEffectiveDate',
    header: 'Not Allowed Effective Date',
  },
  {
    accessorKey: 'isTrust',
    header: 'Trusts',
    maxSize: 80,
    size: 20,
  },
  {
    accessorKey: 'isNonProfit',
    header: 'NonProfits',
    maxSize: 80,
    size: 20,
  },
]

interface ComboOwnershipProps {
  comboId: string | undefined
}

const ComboOwnership = ({ comboId }: ComboOwnershipProps) => {
  const theme = useTheme()
  const { data = [], isPending, isError } = useGetComboOwnership(comboId as string)
  const [selectedDetails, setSelectedDetails] = useState<ComboOwnershipResponse | undefined>(undefined)

  const navMenuOptions: PhoenixBaseTableProps<ComboOwnershipResponse>['kebabMenuOptions'] = [
    {
      label: 'View Details',
      to: () => '#',
      onClick: ({ row }) => {
        setSelectedDetails(row.original)
        openOwnershipDetailsModal()
      },
      disabled: ({ row }) => {
        const histories = row?.original?.histories
        const shares = row?.original?.owners
        return histories.length + shares.length < 1
      },
    },
    {
      label: 'Issue Ruling',
      to: () => '#',
      disabled: () => true,
    },
    {
      label: 'Request Ownership',
      to: () => '#',
      disabled: () => true,
    },
  ]

  useSetAlertDetails([isError])
  const {
    value: isOwnershipDetailsModalOpen,
    onFalse: closeOwnershipDetailsModal,
    onTrue: openOwnershipDetailsModal,
  } = useBoolean(false)

  return (
    <>
      <PhoenixBaseCard cardTitle={'Ownership'}>
        <PhoenixBaseTable
          columns={columns}
          data={data}
          isLoading={isPending}
          kebabMenuOptions={navMenuOptions}
          overrides={{
            enableFilters: true,
            enableTopToolbar: true,
            enableExpanding: true,
            enableExpandAll: true,
            renderDetailPanel: ({ row }) => {
              const { entityNotes } = row.original
              if (entityNotes?.length === 0) return <NoResultsFound />
              const comDTO = entityNotes?.map((note) => ({
                date: note.loadDate,
                time: note.loadTime,
                userName: note.user,
                message: note.message,
                conversationGuid: note.communicatorConversationGuid,
              }))

              return <CommunicationTimeline content={comDTO} />
            },
            muiDetailPanelProps: () => ({
              sx: {
                backgroundColor: theme.palette.grey[100],
                padding: 0,
              },
            }),
          }}
        />
      </PhoenixBaseCard>
      <OwnershipDetailsModal
        shares={selectedDetails?.owners}
        entityName={selectedDetails?.entityName}
        histories={selectedDetails?.histories}
        isOpen={isOwnershipDetailsModalOpen}
        onClose={closeOwnershipDetailsModal}
      />
    </>
  )
}

export default ComboOwnership
