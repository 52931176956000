import { Box } from '@mui/material'
import { BreadcrumbBar } from 'componix'
import { UUID } from 'crypto'
import { useParams } from 'react-router-dom'
import { useGetAncestors } from '../cache/ancestorCache'
import { useGetCarrierGroupGutterInfo } from '../cache/carrierCache'
import CarrierGroupGutterCard from '../components/Carrier/CarrierGroupLeftGutterCard'
import CarrierContactsCard from '../components/Carriers/CarrierContactsCard'
import CarrierDetailsCard from '../components/Carriers/CarrierDetailsCard'
import CarrierGroupHistoryCard from '../components/Carriers/CarrierGroupHistoryCard'
import CarrierInfoCard from '../components/Carriers/CarrierInfoCard'
import CarrierLossCostMultiplierCard from '../components/Carriers/CarrierLossCostMultiplierCard'
import CarrierRevisionsCard from '../components/Carriers/CarrierRevisionsCard'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import { useNavbar } from '../contexts/NavbarContext'
import useSetAlertDetails from '../hooks/workQueue/useSetAlertDetails'
import { AddressTypeDictionary } from '../utils/addressTypes.enum'
import { HierarchyTypes } from '../utils/hierarchy.enum'

const CarrierDetails = () => {
  const { pageStyle } = useNavbar()
  const { carrierGuid } = useParams()
  const { data: ancestorData } = useGetAncestors(carrierGuid as UUID, HierarchyTypes.CARRIER)
  const {
    data: carrierGroupData,
    isPending: isCarrierGroupLoading,
    isError: isCarrierGroupError,
  } = useGetCarrierGroupGutterInfo(carrierGuid as string)

  useSetAlertDetails([isCarrierGroupError])

  // Hard code address types for now till api endpoint is available
  const CarrierContactAddressTypes = AddressTypeDictionary

  return (
    <Box style={{ ...pageStyle }}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Carriers`,
            to: `/carriers`,
          },
          {
            label: `Carrier Group ${ancestorData?.carrierGroup?.identifier}`,
            to: `/carriers/group/${ancestorData?.carrierGroup?.guid}`,
          },
          {
            label: `Carrier ${ancestorData?.carrier?.identifier}`,
            to: `/carriers/${carrierGuid}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <div>
            <CarrierDetailsCard guid={carrierGuid} />
            <CarrierLossCostMultiplierCard guid={carrierGuid} />
            <CarrierRevisionsCard guid={carrierGuid} />
            <CarrierContactsCard guid={carrierGuid} contactTypes={CarrierContactAddressTypes} />
            <CarrierGroupHistoryCard guid={carrierGuid} />
          </div>
        }
        sideRailComponentArray={[
          <CarrierInfoCard key="cid" guid={carrierGuid} />,
          <CarrierGroupGutterCard key="cggc" carrierGroup={carrierGroupData} isLoading={isCarrierGroupLoading} />,
        ]}
      />
    </Box>
  )
}

export default CarrierDetails
