import { PhoenixBaseTable } from 'componix'
import React from 'react'
import CarrierLossCostMultiplier from '../../../../models/Carrier/CarrierLossCostMultiplier'
import CarrierLossCostMultiplierTableColumns from './CarrierLossCostMultiplierTableColumns'

interface CarrierLossCostMultiplierTableProps {
  data: CarrierLossCostMultiplier[]
  isLoading: boolean
}

export const CarrierLossCostMultiplierTable = ({ data, isLoading }: CarrierLossCostMultiplierTableProps) => {
  return <PhoenixBaseTable columns={CarrierLossCostMultiplierTableColumns} data={data} isLoading={isLoading} />
}

export default CarrierLossCostMultiplierTable
