import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'

export interface Option {
  id: string
  value: string
  label: string
}

interface DropdownProps {
  handleSelect: (event: {
    target: {
      value: string
    }
  }) => void

  labelText: string

  value: string

  error: boolean

  helperText: string | null

  options: Option[]

  fullWidth?: boolean

  size?: 'small' | 'medium'
}

const Dropdown = ({ handleSelect, labelText, error, helperText, options, value, fullWidth, size }: DropdownProps) => {
  return (
    <FormControl
      data-testid="selectDropdown-Form"
      error={error}
      variant="outlined"
      fullWidth={fullWidth ?? true}
      size={size}
    >
      <InputLabel id={`dropdown-${labelText}-label`}>{labelText}</InputLabel>
      <Select
        data-testid={`search-${labelText}-dropdown`}
        labelId={`dropdown-${labelText}-label`}
        id={`search-${labelText}-dropdown`}
        value={value}
        label={labelText}
        onChange={handleSelect}
      >
        {options.map((option) => (
          <MenuItem data-testid={option.id} id={option.id} key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText id="selectHelperText">{helperText}</FormHelperText>
    </FormControl>
  )
}
export default Dropdown
