import { Box } from '@mui/material'
import React from 'react'
import { useNavbar } from '../contexts/NavbarContext'

const Classes = () => {
  const { pageStyle } = useNavbar()
  return <Box style={{ ...pageStyle, marginBottom: '24px' }}>Class Page</Box>
}

export default Classes
