export enum AddressTypeCodes {
  MainCarrierContact = '01',
  UnitStat = '02',
  Audit = '10',
  Underwriting = '11',
  Policy = '12',
  GlobalFiningInvoice = '30',
  CDXContact = '32',
}

export enum AddressTypeDescriptions {
  MainCarrierContact = 'Main Carrier Contact',
  UnitStat = 'Unit Stat',
  Audit = 'Audit',
  Underwriting = 'Underwritting',
  Policy = 'Policy',
  GlobalFiningInvoice = 'Global Fining Invoice',
  CDXContact = 'CDX Contact',
}

export const AddressTypeDictionary = [
  {
    addressTypeCode: AddressTypeCodes.MainCarrierContact,
    addressTypeDescription: AddressTypeDescriptions.MainCarrierContact,
  },
  {
    addressTypeCode: AddressTypeCodes.UnitStat,
    addressTypeDescription: AddressTypeDescriptions.UnitStat,
  },
  {
    addressTypeCode: AddressTypeCodes.Audit,
    addressTypeDescription: AddressTypeDescriptions.Audit,
  },
  {
    addressTypeCode: AddressTypeCodes.Underwriting,
    addressTypeDescription: AddressTypeDescriptions.Underwriting,
  },
  {
    addressTypeCode: AddressTypeCodes.Policy,
    addressTypeDescription: AddressTypeDescriptions.Policy,
  },
  {
    addressTypeCode: AddressTypeCodes.GlobalFiningInvoice,
    addressTypeDescription: AddressTypeDescriptions.GlobalFiningInvoice,
  },
  {
    addressTypeCode: AddressTypeCodes.CDXContact,
    addressTypeDescription: AddressTypeDescriptions.CDXContact,
  },
]
