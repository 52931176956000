import { PhoenixBaseTable } from 'componix'
import React from 'react'
import { CarrierRevisionHistory } from '../../../../models/Carrier/CarrierRevisionHistory'
import CarrierRevisionsTableColumns from './CarrierRevisionsTableColumns'

interface CarrierRevisionsTableProps {
  data: CarrierRevisionHistory[]
  isLoading: boolean
}

export const CarrierRevisionsTable = ({ data, isLoading }: CarrierRevisionsTableProps) => {
  return (
    <PhoenixBaseTable
      columns={CarrierRevisionsTableColumns}
      data={data}
      isLoading={isLoading}
      overrides={{
        enableFilters: true,
        enableTopToolbar: true,
      }}
    />
  )
}

export default CarrierRevisionsTable
