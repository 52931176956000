import { PhoenixBaseCard } from 'componix'
import { useGetCarrierGroupNameHistory } from '../../../cache/carrierCache'
import useSetAlertDetails from '../../../hooks/workQueue/useSetAlertDetails'
import CarrierGroupNameHistoryTable from './CarrierGroupNameHistoryTable'

interface CarrierGroupNameHistoryCardProps {
  carrierGroupGuid: string
}
const CarrierGroupNameHistoryCard = ({ carrierGroupGuid }: CarrierGroupNameHistoryCardProps) => {
  const { data, isLoading, lazyFetch, isError } = useGetCarrierGroupNameHistory(carrierGroupGuid as string)
  useSetAlertDetails([isError])
  return (
    <PhoenixBaseCard cardTitle="Name History" collapsible={true} onExpand={lazyFetch}>
      <CarrierGroupNameHistoryTable data={data ?? []} isLoading={isLoading} />
    </PhoenixBaseCard>
  )
}

export default CarrierGroupNameHistoryCard
