import { MRT_ColumnDef } from 'material-react-table'
import AddressDTOModel from '../../../../models/AddressDTOModel'
import CarrierContactInformation from '../../../../models/Carrier/CarrierContactInformation'
import { addressFormatter } from '../../../../utils/addressFormatter'

export const CarrierContactsTableColumns: MRT_ColumnDef<CarrierContactInformation>[] = [
  {
    accessorKey: 'contact',
    header: 'Contact',
    accessorFn: (row) => {
      if (row.contactTitle) {
        return `${row.contactName}, ${row.contactTitle}`
      }
      return row.contactName
    },
    maxSize: 200,
  },
  {
    accessorKey: 'address',
    header: 'Address',
    Cell: ({ cell }) => {
      return addressFormatter(cell.getValue<AddressDTOModel>())
    },
    size: 200,
  },
  {
    accessorKey: 'phone',
    header: 'Phone',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
]

export default CarrierContactsTableColumns
