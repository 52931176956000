import { PhoenixBaseTable } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { CarrierGroupHistoryResponse } from '../../../models/Carrier/CarrierGroupHistoryResponse'
import { tableCellTitleCase } from '../../../utils/table/tableCellTitleCase'

const columns: MRT_ColumnDef<CarrierGroupHistoryResponse>[] = [
  {
    header: 'Name',
    accessorKey: 'carrierGroupName',
    Cell: ({ cell }) => tableCellTitleCase(cell.getValue<string>()),
  },
  {
    header: 'Effective Date',
    accessorKey: 'carrierGroupEffectiveDate',
  },
  {
    header: 'Expiration Date',
    accessorKey: 'carrierGroupExpirationDate',
  },
]

interface CarrierGroupNameHistoryProps {
  data: CarrierGroupHistoryResponse[]
  isLoading: boolean
}

const CarrierGroupNameHistoryTable = ({ data, isLoading }: CarrierGroupNameHistoryProps) => {
  return <PhoenixBaseTable columns={columns} data={data ?? []} isLoading={isLoading} />
}

export default CarrierGroupNameHistoryTable
