import { Box, Button, Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { CommunicationTimeline, ErrorAttachment, PhoenixBaseCard, PhoenixBaseTable } from 'componix'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetCarrierErrorNotifications, useResolveCarrierError } from '../../../cache/workQueueCache'
import useAttachmentsModal from '../../../hooks/useAttachmentsModal'
import useErrorProcessingModal from '../../../hooks/useErrorProcessingModal'
import useSetAlertDetails from '../../../hooks/workQueue/useSetAlertDetails'
import { UpdateErrorsModel } from '../../../models/PolicyDetails/UpdateErrorsModel'
import { PolicyCarrierErrorResponseModel } from '../../../models/WorkItemDetails/CarrierErrorNotification'
import WorkItemDetailsModel from '../../../models/WorkItemDetails/WorkItemDetailsModel'
import AcceptErrorModal from '../../Modals/AcceptErrorModal'
import AttachmentsModal from '../../Modals/AttachmentsModal'
import RejectErrorModal from '../../Modals/RejectErrorModal'
import { CarrierErrorColumns } from './CarrierErrorColumns'
import { PolicyCarrierErrorHeader } from './PolicyCarrierErrorHeader'

interface CarrierErrorNotificationsProps {
  workItem: WorkItemDetailsModel | undefined
  isLoadingWorkItemDetails: boolean
}

export const PolicyCarrierErrorNotification = ({
  workItem,
  isLoadingWorkItemDetails,
}: CarrierErrorNotificationsProps) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {
    isModalOpen: isAttachmentsModalOpen,
    openModal: openAttachmentsModal,
    closeModal: closeAttachmentsModal,
    currentAttachments: attachments,
  } = useAttachmentsModal()

  const {
    data: carrierErrorNotifications,
    isPending: isCarrierErrorNotificationsLoading,
    isError: isCarrierErrorNotificationsError,
  } = useGetCarrierErrorNotifications(workItem?.workQueue_WorkItemID as string)

  const {
    mutateAsync,
    isSuccess: isResolveCarrierErrorSuccess,
    isPending: isResolveCarrierErrorLoading,
  } = useResolveCarrierError(workItem?.workQueue_WorkItemID as string, queryClient)

  const updatePolicyCarrierErrors = async (request: UpdateErrorsModel) => {
    return await mutateAsync({
      userAccepted: request.userAccepted,
      workItemGuid: workItem?.workQueue_WorkItemID as string,
      entityType: 'policy',
      responseText: request?.responseText,
    })
  }

  const {
    isModalOpen: isRejectModalOpen,
    openModal: openRejectModal,
    closeModal: closeRejectModal,
    rejectErrors,
  } = useErrorProcessingModal(updatePolicyCarrierErrors)

  const {
    isModalOpen: isAcceptModalOpen,
    openModal: openAcceptModal,
    closeModal: closeAcceptModal,
    acceptErrors,
  } = useErrorProcessingModal(updatePolicyCarrierErrors)

  useSetAlertDetails([isCarrierErrorNotificationsError])

  useEffect(() => {
    if (isResolveCarrierErrorSuccess) navigate('/workqueue')
  }, [isResolveCarrierErrorSuccess, navigate])

  const error = carrierErrorNotifications?.error
  const errorExists = error && error?.errors.length > 0
  const isLoading = isLoadingWorkItemDetails || isCarrierErrorNotificationsLoading

  return (
    <PhoenixBaseCard contentPadded cardTitle="Carrier Error Notification">
      <PhoenixBaseCard cardTitle={'Policy Error Details'} variantType={'Secondary'}>
        <PolicyCarrierErrorHeader error={error as PolicyCarrierErrorResponseModel} isLoading={isLoading} />
        <PhoenixBaseTable columns={CarrierErrorColumns} data={error?.errors ?? []} isLoading={isLoading} />
        <Grid container>
          <Grid item xs={9}>
            <CommunicationTimeline
              actions={{
                attachmentsFunc: function (rows: ErrorAttachment[]): void {
                  openAttachmentsModal(rows)
                },
              }}
              content={carrierErrorNotifications?.communications ?? []}
            />
          </Grid>
          <Grid item xs={3}>
            {workItem?.workItemStatus === 'Open' && errorExists && (
              <Box sx={{ display: 'flex', gap: '0.5rem', mt: '22px' }}>
                <Button
                  color="success"
                  onClick={() => openAcceptModal([error?.errors[0].editID as string])}
                  variant="contained"
                  disabled={isResolveCarrierErrorLoading}
                >
                  Accept
                </Button>
                <Button
                  color="error"
                  onClick={() => openRejectModal([error?.errors[0].editID as string])}
                  variant="contained"
                  disabled={isResolveCarrierErrorLoading}
                >
                  Reject
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </PhoenixBaseCard>

      <AcceptErrorModal
        isOpen={isAcceptModalOpen}
        onCancel={closeAcceptModal}
        onAccept={() => acceptErrors(workItem?.workQueue_WorkItemID, 'policy')}
      />
      <RejectErrorModal
        isOpen={isRejectModalOpen}
        onCancel={closeRejectModal}
        onReject={(responseText: string) => rejectErrors(workItem?.workQueue_WorkItemID, 'policy', responseText)}
      />
      <AttachmentsModal isOpen={isAttachmentsModalOpen} onCancel={closeAttachmentsModal} attachments={attachments} />
    </PhoenixBaseCard>
  )
}
