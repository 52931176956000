import { Person } from '@mui/icons-material'
import WorkIcon from '@mui/icons-material/Work'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Step, StepLabel, Stepper } from '@mui/material'
import { FieldGroup, FieldGroupAddress, PhoenixBaseCard, PhoenixLink } from 'componix'
import { Link, useNavigate } from 'react-router-dom'
import FindMatchForms from '../../models/WorkItemDetails/FindMatchForms'
import { MatchSuccessResponse } from '../../models/WorkItemDetails/MatchSuccessResponse'
import { SuggestedMatchModel } from '../../models/WorkItemDetails/SuggestedMatchModel'
import { UnmatchedTransactionModel } from '../../models/WorkItemDetails/UnmatchedTransactionModel'
import { handleTitleCase } from '../../utils/handleTitleCase'
import PhoenixModalHeader from '../Modals/PhoenixModalHeader'

export interface MatchPolicyToCoverageModalProps {
  data: UnmatchedTransactionModel | null | undefined
  isOpen: boolean
  handleClose: () => void
  setFoundMatch: (match: SuggestedMatchModel | null) => void
  match: SuggestedMatchModel | null
  successMatch: MatchSuccessResponse | null
  isMatchLoading: boolean
  activeStep: number
  handleConfirmMatch: () => void
}

const MatchPolicyToCoverageModal = ({
  data,
  isOpen,
  handleClose,
  setFoundMatch,
  match,
  successMatch,
  isMatchLoading,
  activeStep,
  handleConfirmMatch,
}: MatchPolicyToCoverageModalProps) => {
  const navigate = useNavigate()
  const doesPolicyHaveMatch = !!match

  const headerHandleClose = () => {
    handleClose()
    if (activeStep === 1) navigate('/')
  }

  return (
    <Dialog fullWidth maxWidth={'sm'} open={isOpen}>
      <PhoenixModalHeader title={'Match Policy To Coverage'} isErrorColor={false} handleClose={headerHandleClose} />
      <DialogContent>
        <Box display={'flex'} justifyContent={'center'} mb={2}>
          <Stepper sx={{ minWidth: '240px' }} activeStep={activeStep}>
            <Step>
              <StepLabel>Match</StepLabel>
            </Step>
            <Step>
              <StepLabel>Results</StepLabel>
            </Step>
          </Stepper>
        </Box>
        {activeStep === 0 ? (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <PhoenixBaseCard cardTitle={'Transaction Details'} variantType={'Secondary'}>
                <Box p={1}>
                  <FieldGroup label={'Combo ID'} value={data?.combo?.identifier} />
                  <FieldGroup label={'Coverage ID'} value={data?.coverage?.identifier} />
                  <FieldGroup icon={<Person />} label={'Primary Name'} value={handleTitleCase(data?.employer)} />
                  <FieldGroupAddress
                    city={handleTitleCase(data?.address.city)}
                    state={handleTitleCase(data?.address.state)}
                    street={handleTitleCase(data?.address.street)}
                    zipCode={handleTitleCase(data?.address.zipCode)}
                  />
                  <FieldGroup icon={<WorkIcon />} label={'FEIN'} value={data?.fein} />
                </Box>
              </PhoenixBaseCard>
            </Grid>
            <Grid item xs={6}>
              <FindMatchForms
                setFoundMatch={setFoundMatch}
                match={match}
                doesPolicyHaveMatch={doesPolicyHaveMatch}
                isModalOpen={isOpen}
              />
            </Grid>
          </Grid>
        ) : (
          <Box p={1}>
            <FieldGroup
              label={'Combo ID'}
              value={<PhoenixLink value={String(successMatch?.comboId)} to={`/combos/${successMatch?.comboGuid}`} />}
            />
            <FieldGroup
              label={'Coverage ID'}
              value={
                <PhoenixLink value={String(successMatch?.coverageId)} to={`/coverages/${successMatch?.coverageId}`} />
              }
            />
            <FieldGroup label={'Policy Number'} value={data?.transactionDetails?.policyInformation.policyNumber} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {activeStep === 0 ? (
          <>
            <Button onClick={handleClose} disabled={isMatchLoading}>
              Cancel
            </Button>
            <LoadingButton
              variant={'contained'}
              disabled={!doesPolicyHaveMatch || isMatchLoading}
              onClick={() => handleConfirmMatch()}
              loading={isMatchLoading}
            >
              Confirm Match
            </LoadingButton>
          </>
        ) : (
          <Link to={'/workqueue'}>
            <Button variant={'contained'} onClick={handleClose}>
              Close
            </Button>
          </Link>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default MatchPolicyToCoverageModal
