import { PhoenixBaseCard } from 'componix'
import { useGetCarrierRevisionHistory } from '../../../cache/carrierCache'
import useSetAlertDetails from '../../../hooks/workQueue/useSetAlertDetails'
import CarrierRevisionsTable from './CarrierRevisionTable'

interface CarrierRevisionsCardProps {
  guid: string | undefined
}

export const CarrierRevisionsCard = ({ guid }: CarrierRevisionsCardProps) => {
  const { data = [], isLoading, isError, lazyFetch } = useGetCarrierRevisionHistory(guid as string)
  useSetAlertDetails([isError])

  return (
    <PhoenixBaseCard cardTitle={'Revisions'} collapsible={true} onExpand={lazyFetch} isLoading={isLoading}>
      <CarrierRevisionsTable data={data} isLoading={isLoading} />
    </PhoenixBaseCard>
  )
}

export default CarrierRevisionsCard
