import CarrierContactInformation from '../models/Carrier/CarrierContactInformation'
import CarrierDetailsResponse from '../models/Carrier/CarrierDetailsResponse'
import { CarrierGroupCarrierResponse } from '../models/Carrier/CarrierGroupCarrierResponse'
import { CarrierGroupHistoryResponse } from '../models/Carrier/CarrierGroupHistoryResponse'
import CarrierGroupInfoResponse from '../models/Carrier/CarrierGroupInfoResponse'
import CarrierLossCostMultiplier from '../models/Carrier/CarrierLossCostMultiplier'
import CarrierResponse from '../models/Carrier/CarrierResponse'
import { CarrierRevisionHistory } from '../models/Carrier/CarrierRevisionHistory'
import api, { ApiResponse } from '../utils/apiService'
import { Endpoints } from '../utils/endpoint'

const getCarrierList = async (): Promise<CarrierResponse[]> => {
  const response = await api.get(`${Endpoints.Carriers}`)
  return response.data
}

const getCarrierGroupInfo = async (carrierGuid: string): Promise<CarrierGroupInfoResponse> => {
  const response = await api.get(`${Endpoints.Carriers}/groups/${carrierGuid}/info`)
  return response.data
}

const getCarrierGroupCarrierList = async (carrierGuid: string): Promise<CarrierGroupCarrierResponse[]> => {
  const response = await api.get(`${Endpoints.Carriers}/groups/${carrierGuid}/carriers`)
  return response.data
}
const getCarrierGroupInfoByCarrierID = async (carrierID: string) => {
  const response = await api.get(`${Endpoints.Carriers}/${carrierID}/group-info`)
  return response.data
}

const getCarrierDetails = async (carrierGuid: string): Promise<CarrierDetailsResponse> => {
  const response = await api.get(`${Endpoints.Carriers}/${carrierGuid}/details`)
  return response.data
}

const getCarrierInfo = async (carrierGuid: string): Promise<CarrierResponse> => {
  const response = await api.get(`${Endpoints.Carriers}/${carrierGuid}/information`)
  return response.data
}

const getCarrierLossCost = async (carrierGuid: string): Promise<CarrierLossCostMultiplier[]> => {
  const response = await api.get(`${Endpoints.Carriers}/${carrierGuid}/lossCost`)
  return response.data
}

const getCarrierGroupNameHistory = async (carrierGroupGuid: string) => {
  const response: ApiResponse<CarrierGroupHistoryResponse[]> = await api.get(
    `${Endpoints.Carriers}/groups/${carrierGroupGuid}/nameHistory`
  )
  return response.data
}

const getCarrierGroupHistory = async (carrierGuid: string) => {
  const response: ApiResponse<CarrierGroupHistoryResponse[]> = await api.get(
    `${Endpoints.Carriers}/${carrierGuid}/groupHistory`
  )
  return response.data
}

const getCarrierContacts = async (
  carrierGuid: string,
  carrierContactType: string
): Promise<CarrierContactInformation[]> => {
  const response = await api.get(`${Endpoints.Carriers}/${carrierGuid}/contacts/${carrierContactType}`)
  return response.data
}

const getCarrierRevisionHistory = async (carrierGuid: string): Promise<CarrierRevisionHistory[]> => {
  const response = await api.get(`${Endpoints.Carriers}/${carrierGuid}/statusRevisionHistory`)
  return response.data
}

export {
  getCarrierList,
  getCarrierGroupHistory,
  getCarrierGroupInfo,
  getCarrierGroupCarrierList,
  getCarrierGroupInfoByCarrierID,
  getCarrierGroupNameHistory,
  getCarrierDetails,
  getCarrierInfo,
  getCarrierLossCost,
  getCarrierContacts,
  getCarrierRevisionHistory,
}
