import { PhoenixBaseCard } from 'componix'
import { useGetCarrierGroupHistory } from '../../../cache/carrierCache'
import useSetAlertDetails from '../../../hooks/workQueue/useSetAlertDetails'
import CarrierGroupHistoryTable from './CarrierGroupHistoryTable'

interface CarrierGroupHistoryCardProps {
  guid: string | undefined
}

export const CarrierGroupHistoryCard = ({ guid }: CarrierGroupHistoryCardProps) => {
  const { data = [], isLoading, isError, lazyFetch } = useGetCarrierGroupHistory(guid as string)
  useSetAlertDetails([isError])

  return (
    <PhoenixBaseCard cardTitle={'Group History'} collapsible={true} onExpand={lazyFetch} isLoading={isLoading}>
      <CarrierGroupHistoryTable data={data} isLoading={isLoading} />
    </PhoenixBaseCard>
  )
}

export default CarrierGroupHistoryCard
