import { PhoenixBaseTable } from 'componix'
import React from 'react'
import CarrierContactInformation from '../../../../models/Carrier/CarrierContactInformation'
import CarrierContactsTableColumns from './CarrierContactsTableColumns'

interface CarrierContactsTableProps {
  data: CarrierContactInformation[]
  isLoading: boolean
}

export const CarrierContactsTable = ({ data, isLoading }: CarrierContactsTableProps) => {
  return <PhoenixBaseTable columns={CarrierContactsTableColumns} data={data} isLoading={isLoading} />
}

export default CarrierContactsTable
