import { MRT_ColumnDef } from 'material-react-table'
import { CarrierGroupHistoryResponse } from '../../../../models/Carrier/CarrierGroupHistoryResponse'

export const CarrierGroupHistoryTableColumns: MRT_ColumnDef<CarrierGroupHistoryResponse>[] = [
  {
    accessorKey: 'carrierGroupId',
    header: 'Group ID',
    size: 50,
  },
  {
    accessorKey: 'carrierGroupName',
    header: 'Group Name',
  },
  {
    accessorKey: 'carrierGroupEffectiveDate',
    header: 'Effective Date',
    size: 50,
  },
  {
    accessorKey: 'carrierGroupExpirationDate',
    header: 'Expiration Date',
  },
]

export default CarrierGroupHistoryTableColumns
