import { Box, Dialog } from '@mui/material'
import { DataFieldBlankValue, FieldGroup, PhoenixBaseCard, PhoenixBaseTable } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { History, Owner } from '../../models/WorkItemDetails/ComboOwnershipResponse'
import { handleTitleCase } from '../../utils/handleTitleCase'
import PhoenixModalHeader from './PhoenixModalHeader'

interface OwnershipDetailsModalProps {
  shares: Owner[] | undefined
  histories: History[] | undefined
  entityName: string | undefined
  isOpen: boolean
  onClose: () => void
}

const SharesColumns: MRT_ColumnDef<Owner>[] = [
  {
    accessorKey: 'ownerName',
    header: 'Owner',
    size: 20,
    Cell: ({ cell }) => handleTitleCase(cell.getValue<string>()),
  },
  {
    accessorKey: 'sharesOwned',
    header: 'Shares Owned',
    size: 20,
    Cell: ({ cell }) => cell.getValue<number>().toFixed(1),
  },
  {
    accessorKey: 'percentageOwned',
    header: '% Owned',
    size: 20,
    // eslint-disable-next-line react/prop-types
    Cell: ({ cell }) => (cell.getValue() == null ? <DataFieldBlankValue /> : `${cell.getValue<number>().toFixed(1)}%`),
  },
]

const HistoriesColumn: MRT_ColumnDef<History>[] = [
  {
    header: 'Event',
    accessorKey: 'eventType',
    size: 50,
  },
  {
    header: 'Date',
    accessorKey: 'date',
    size: 20,
  },
  {
    header: 'User ID',
    accessorKey: 'userID',
    size: 20,
  },
  {
    accessorKey: 'letterID',
    header: 'Letter ID',
    size: 20,
  },
]

const OwnershipDetailsModal = ({ isOpen, onClose, shares, histories, entityName }: OwnershipDetailsModalProps) => {
  return (
    <Dialog fullWidth open={isOpen} max-width={'md'} data-testid="ownership-details">
      <PhoenixModalHeader title={'Ownership Details'} handleClose={onClose} isErrorColor={false} />
      <Box sx={{ paddingX: 2.5, paddingY: 1 }}>
        <FieldGroup label="Entity" value={handleTitleCase(entityName)} />
      </Box>
      <Box sx={{ paddingX: 2 }}>
        <PhoenixBaseCard collapsible={false} cardTitle="Shares" variantType="Secondary">
          <PhoenixBaseTable columns={SharesColumns} data={shares ?? []} />
        </PhoenixBaseCard>
        <PhoenixBaseCard collapsible={false} cardTitle="History" variantType="Secondary">
          <PhoenixBaseTable columns={HistoriesColumn} data={histories ?? []} />
        </PhoenixBaseCard>
      </Box>
    </Dialog>
  )
}

export default OwnershipDetailsModal
