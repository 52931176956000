import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Box, Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard } from 'componix'
import { useGetCarrierInfo } from '../../cache/carrierCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'

interface CarrierDetailsCardProps {
  guid: string | undefined
}

const CarrierInfoCard = ({ guid }: CarrierDetailsCardProps) => {
  const { data, isError, isPending } = useGetCarrierInfo(guid as string)

  useSetAlertDetails([isError])

  return (
    <PhoenixBaseCard cardTitle="Carrier Information" contentPadded={true}>
      <Box sx={{ padding: '16px', width: '100%' }}>
        <FieldGroup label="Carrier ID" value={data?.carrierId} isLoading={isPending} />
        <FieldGroup label="Carrier Name" value={data?.carrierName} isLoading={isPending} />
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <FieldGroup label="NCCI ID" value={data?.ncciCode} isLoading={isPending} />
          </Grid>
          <Grid item lg={6} xs={12}>
            <FieldGroup label="NAIC ID" value={data?.naicCode} isLoading={isPending} />
          </Grid>
        </Grid>
        <FieldGroup
          label="Effective Date"
          value={data?.carrierEffectiveDate}
          icon={<CalendarTodayIcon />}
          isLoading={isPending}
        />
        <FieldGroup
          label="Expiration Date"
          value={data?.carrierExpirationDate}
          icon={<CalendarTodayIcon />}
          isLoading={isPending}
        />
      </Box>
    </PhoenixBaseCard>
  )
}

export default CarrierInfoCard
