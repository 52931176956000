import { StatusBadge } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { CarrierRevisionHistory } from '../../../../models/Carrier/CarrierRevisionHistory'

export const CarrierRevisionsTableColumns: MRT_ColumnDef<CarrierRevisionHistory>[] = [
  {
    accessorKey: 'changeDate',
    header: 'Change Date',
    size: 40,
  },
  {
    accessorKey: 'carrierName',
    header: 'Name',
    size: 400,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    // eslint-disable-next-line react/prop-types
    Cell: ({ cell }) => <StatusBadge description={cell.getValue<string>()} />,
    size: 40,
  },
  {
    accessorKey: 'effectiveDate',
    header: 'Effective Date',
    size: 40,
  },
  {
    accessorKey: 'expirationDate',
    header: 'Expiration Date',
    size: 40,
  },
  {
    accessorKey: 'userID',
    header: 'User ID',
    size: 40,
  },
  {
    accessorKey: 'comments',
    header: 'Comments',
  },
]

export default CarrierRevisionsTableColumns
