import { PhoenixBaseTable } from 'componix'
import React from 'react'
import { CarrierGroupHistoryResponse } from '../../../../models/Carrier/CarrierGroupHistoryResponse'
import CarrierGroupHistoryTableColumns from './CarrierGroupHistoryTableColumns'

interface CarrierGroupHistoryTableProps {
  data: CarrierGroupHistoryResponse[]
  isLoading: boolean
}

export const CarrierGroupHistoryTable = ({ data, isLoading }: CarrierGroupHistoryTableProps) => {
  return <PhoenixBaseTable columns={CarrierGroupHistoryTableColumns} data={data} isLoading={isLoading} />
}

export default CarrierGroupHistoryTable
